import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';
import { config } from '../constant/config';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { EnvService } from '../env.service';

@Injectable({
  providedIn: 'root'
})
export class CommodityProductsService {
  public regionData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  protected productsLength = new BehaviorSubject(0);
  $productsLength = this.productsLength.asObservable().pipe(distinctUntilChanged());
  
  allRegions = [];
  apiUrl:any;
  constructor(private http: HttpClient,private env: EnvService) {
    this.apiUrl=this.env.apiUrl;
   }
  regionsData(data) {
    this.allRegions = data;
  };

  getRegionsData() {
    return this.allRegions;
  };

  addProduct(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsCommodityProducts/addNewProduct', data);
  };

  addQuality(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsCommodityProducts/addProductQuality', data);
  };

  getProducts(): Observable<any> {
    return this.http.get(this.apiUrl + 'cmsCommodityProducts/getAllProducts');
  };

  addNewregion(data): Observable<any> {
    return this.http.post(this.apiUrl + "cmsCommodityProducts/addRegion", data);
  };
  
  getAllRegions(): Observable<any> {
    return this.http.get(this.apiUrl + "cmsCommodityProducts/getAllRegions");
  };

  editProduct(data): Observable<any> {
    return this.http.post(this.apiUrl + "cmsCommodityProducts/editProduct", data);
  };

  getQualities(data): Observable<any> {
    return this.http.post(this.apiUrl + "cmsCommodityProducts/getAllQualities", data);
  };

  qualityEdit(data): Observable<any> {
    return this.http.post(this.apiUrl + "cmsCommodityProducts/qualityEdit", data);
  };

  getRegionProducts(regionInfo): Observable<any> {
    return this.http.post(this.apiUrl + "cmsCommodityProducts/fetchRegionInfo", regionInfo);
  };
  // getRegionsInfo(): Observable<any> {
  //   return this.http.get(this.apiUrl + "cmsCommodityProducts/getRpi2Regions");
  // };

  getRegionProductsQualities(regionQualities): Observable<any> {
    return this.http.post(this.apiUrl + "cmsCommodityProducts/fetchRegionQualities", regionQualities);
  };
  getPerticularRegion(data): Observable<any> {
    return this.http.post(this.apiUrl + "cmsCommodityProducts/getPerticularRegion", data);
  };

  editQulityInfo(qualityInfo): Observable<any> {
    return this.http.post(this.apiUrl + "cmsCommodityProducts/updateQualityInfo", qualityInfo);
  };
  editProductInfo(productInfo): Observable<any> {
    return this.http.post(this.apiUrl + "cmsCommodityProducts/updateProductInfo", productInfo);
  };
  updateRegion(val): Observable<any> {
    return this.http.post(this.apiUrl + "cmsCommodityProducts/updateRegion", val).pipe(map((region:any) =>{
      if(region.success){
        this.regionData.next(Object.assign(region,{delete:false}));
        return region;
      }else{
        return region;
      }
    }));
  };

  deleteSelectRegion(regionInfo): Observable<any> {
    return this.http.post(this.apiUrl + "cmsCommodityProducts/deleteRegionInfo", regionInfo).pipe(map((region:any) => {
      if(region.success){
        if(region.msg !='This region used in storages'){
          Object.assign(region,{delete:true})
          this.regionData.next(region);
        }
        return region;
      }else{
        return region;
      }
    }));
  };


  setProductsLength(data){
    this.productsLength.next(data);
  }


  checkStorages(data):Observable<any>{
    return this.http.post(this.apiUrl + "cmsCommodityProducts/checkSubscribedStorages", data);
  }

  getAllProductIds(data):Observable<any>{
    return this.http.post(this.apiUrl + "cmsCommodityProducts/getAllProductIds", data)
  }
}
