import { config } from './../constant/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { EnvService } from '../env.service';

@Injectable({
  providedIn: 'root'
})
export class StorageServiceService {
  public storageData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  apiUrl:any;
  constructor(private http: HttpClient, private env: EnvService) {
    this.apiUrl = this.env.apiUrl;
  }
  storageList;
  storages: EventEmitter<any> = new EventEmitter<any>();
  clicked_storages: EventEmitter<any> = new EventEmitter();

  getAllStorages(): Observable<any> {
    return this.http.get(this.apiUrl + "cmsStorages/getStorages").pipe(map((storage: any) => {
      let local_data = storage;
      if (local_data.success) {
        this.storageList = local_data.data;
        this.storages.emit(local_data.data);
        return local_data;
      } else {
        return local_data;
      }
    }));
  };

  getNavChangeEmitter() {
    return this.storages;
  }

  fetchStoragesProducts(data): Observable<any> {
    return this.http.post(this.apiUrl + "cmsStorages/ftchStrgsPrdctsWthSttngs", data)
  }

  getQualitiesBasedOnProduct(data): Observable<any> {
    return this.http.post(this.apiUrl + "cmsStorages/getQualitiesForSRP", data)
  }

  getAllRegions(): Observable<any> {
    return this.http.get(this.apiUrl + "cmsStorages/getRegions");
  };
  updateStorageQuality(data): Observable<any> {
    return this.http.post(this.apiUrl + "cmsStorages/updateStorageQuality", data);
  }
  addNewStorage(data): Observable<any> {
    return this.http.post(this.apiUrl + "cmsStorages/addNewStorage", data);
  };
  editStorage(data): Observable<any> {
    return this.http.post(this.apiUrl + "cmsStorages/editStorage", data)
    .pipe(map((storages: any) => {
      Object.assign(storages,{deleteStrg:false});
      if (storages.success) {
        this.storageData.next(storages);
        return storages;
      } else {
        return storages;
      }
  }));


      // let local_data = storage;
      // if (local_data.success) {
      //   this.storageList = local_data.data;
      //   this.storageData.next(this.storageList);
      //   return local_data;

      //   // this.storageData.next(storages);
      //   // return storages;
      // } else {
      //   this.storageData.next(this.storageList);

      //   return local_data;
      // }
 
  };
  deleteStorage(id): Observable<any> {
    return this.http.post(this.apiUrl + "cmsStorages/deleteStorage", id).pipe(map((storages: any) => {
      Object.assign(storages,{deleteStrg:true});
      if (storages.success && storages.msg != 'This storage subscribed by some clients') {
        this.storageData.next(storages);
        return storages;
      } else {
        return storages;
      }
    }))
  };
  editStorageRegion(data){
    return this.http.post(this.apiUrl + "cmsStorages/changeRegionInStorageQuality",data)
  }


  //check the quality subscribers
  checkThequalitySubscribers(id){
    return this.http.post(this.apiUrl + "cmsStorages/checkThequalitySubscribers",id)

  }
}
